var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.requests.length)?_c('CCard',{staticClass:"table__request"},[_c('CDataTable',{attrs:{"items":_vm.requests,"fields":_vm.fields,"items-per-page":_vm.PARAMS.LIMIT,"outlined":"","pagination":"","items-per-page-select":_vm.selectLimit,"active-page":_vm.recentPage},on:{"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',{staticClass:"custom__badge",attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{staticClass:"btn-outline-custom",attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[(!Boolean(item._toggled))?[_c('span',{staticClass:"prefix-input d-inline-block"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-down']}})],1)]:[_c('span',{staticClass:"prefix-input d-inline-block"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-up']}})],1)]],2)],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('p',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(_vm.$t("FormsField.note"))+": "),_c('span',{staticClass:"font-weight-normal ml-2"},[_vm._v(_vm._s(item.note))])]),(item.cancelReason)?_c('p',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(_vm.$t("FormsField.comment"))+": "),_c('span',{staticClass:"font-weight-normal ml-2"},[_vm._v(_vm._s(item.cancelReason))])]):_vm._e()])],1)]}}],null,false,3108643568)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }