<template>
	<CCard v-if="requests.length" class="table__request">
		<CDataTable
			:items="requests"
			:fields="fields"
			:items-per-page="PARAMS.LIMIT"
			outlined
			pagination
			:items-per-page-select="selectLimit"
			:active-page="recentPage"
			@page-change="pageChange"
		>
			<template #status="{ item }">
				<td class="text-center">
					<CBadge :color="getBadge(item.status)" class="custom__badge">
						{{ item.status }}
					</CBadge>
				</td>
			</template>
			<template #show_details="{ item, index }">
				<td class="py-2">
					<CButton
						color="primary"
						variant="outline"
						square
						size="sm"
						class="btn-outline-custom"
						@click="toggleDetails(item, index)"
					>
						<template v-if="!Boolean(item._toggled)">
							<span class="prefix-input d-inline-block">
								<font-awesome-icon :icon="['fas', 'chevron-down']" />
							</span>
						</template>
						<template v-else>
							<span class="prefix-input d-inline-block">
								<font-awesome-icon :icon="['fas', 'chevron-up']" />
							</span>
						</template>
					</CButton>
				</td>
			</template>
			<template #details="{ item }">
				<CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
					<CCardBody>
						<p class="text-muted font-weight-bold"
							>{{ $t("FormsField.note") }}:
							<span class="font-weight-normal ml-2">{{ item.note }}</span></p
						>
						<p v-if="item.cancelReason" class="text-muted font-weight-bold"
							>{{ $t("FormsField.comment") }}:
							<span class="font-weight-normal ml-2">{{ item.cancelReason }}</span></p
						>
					</CCardBody>
				</CCollapse>
			</template>
		</CDataTable>
	</CCard>
</template>

<script>
const fields = [
	{ key: "date", _style: "min-width:100px" },
	{ key: "delivery_time", _style: "min-width:100px;", _classes: "text-center" },
	{ key: "offer_price", _style: "min-width:100px;", _classes: "text-center" },
	{ key: "amount", _style: "min-width:100px;", _classes: "text-center" },
	{ key: "order_type", _style: "min-width:100px;", _classes: "text-center" },
	{ key: "status", _style: "min-width:100px;", _classes: "text-center" },
	{
		key: "show_details",
		label: "",
		_style: "width:1%",
	},
]
import { PARAMS, FORM_REQUEST_STATUS } from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import { GET_REQUEST_LIST } from "../store/action-types"
const { mapActions, mapState } = createNamespacedHelpers("forms")
export default {
	name: "FormRequestTable",
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			fields,
			details: [],
			collapseDuration: 0,
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			recentPage: 1,
			PARAMS,
		}
	},
	computed: {
		...mapState(["requests"]),
		selectLimit() {
			let options = process.env.VUE_APP_LIMIT_SEARCH_AVAILABLE.split(",")
			return {
				label: "Paging options:",
				values: options,
			}
		},
	},
	created() {
		this.setupData()
	},
	methods: {
		...mapActions({ GET_REQUEST_LIST }),
		setupData() {
			const queryParams = this.$route.query
			this.limit = queryParams.limit || this.limit
			this.offset = queryParams.offset || this.offset

			this.recentPage = Math.floor(this.offset / this.limit) + 1

			this.search()
		},
		getBadge(status) {
			switch (status.toUpperCase()) {
				case FORM_REQUEST_STATUS.ACCEPTED:
					return "success"
				case FORM_REQUEST_STATUS.DECLINED:
					return "warning"
				default:
					return "danger"
			}
		},
		toggleDetails(item, index) {
			this.$set(this.requests[index], "_toggled", !item._toggled)
			this.collapseDuration = 300
			this.$nextTick(() => {
				this.collapseDuration = 0
			})
		},
		async search() {
			let params = {
				limit: this.limit,
				offset: this.offset,
			}
			const query = this.$route.query
			this.$router.replace({ query: { ...query, ...params } }).catch(() => {})
			await this.GET_REQUEST_LIST({ id: this.id })
		},
		pageChange(e) {
			this.offset = (e - 1) * this.limit
			this.recentPage = e
			this.search()
		},
	},
}
</script>
<style lang="scss">
.table__request {
	.table-responsive {
		border-right: none;
	}

	ul.pagination {
		justify-content: flex-end !important;
	}
}
.custom__badge {
	font-size: 100%;
	padding: 0.5em;
	color: #fff !important;
}

.btn-outline-custom {
	min-width: 0;
}
</style>
